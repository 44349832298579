<template>
  <v-dialog
    max-width="360"
    :disabled="isLoading"
    :value="value"
    @input="(e) => $emit('input', e)"
  >
    <v-card
      :disabled="isLoading"
      :loading="isLoading"
    >
      <v-card-title>
        Edit Profile
      </v-card-title>

      <!-- Show the preview image if possible -->
      <div class="checkerboard-background mx-6">
        <v-img
          v-if="previewLogo"
          :src="previewLogo"
          height="100"
          class="mb-4"
          contain
        />
      </div>

      <v-card-text
        v-if="isWhitelisted"
        class="pb-0"
      >
        <!-- Only show upload field if allowed -->
        <v-file-input
          v-model="form.logo"
          label="Upload Logo"
          accept="image/png, image/jpeg"
          :prepend-icon="null"
          prepend-inner-icon="face"
          :hide-details="!$v.form.logo.$anyError"
          :error-messages="$v.form.logo.$anyError ? ['Please upload a logo under 1MB'] : null"
          @change="handleLogoChange"
          class="mb-3"
          outlined
          dense
        ></v-file-input>
      </v-card-text>

      <!-- show the preview image if possible -->
      <div class="checkerboard-background mx-6">
        <v-img
          v-if="previewWatermark"
          :src="previewWatermark"
          height="100"
          class="mb-4"
          contain
        />
      </div>

      <v-card-text
        v-if="isWhitelisted"
        class="pb-0"
      >
        <!-- Only show upload field if allowed -->
        <v-file-input
          v-if="isWhitelisted"
          v-model="form.watermark"
          label="Upload Watermark"
          accept="image/png, image/jpeg"
          :prepend-icon="null"
          prepend-inner-icon="branding_watermark"
          :hide-details="!$v.form.watermark.$anyError"
          :error-messages="$v.form.watermark.$anyError ? ['Please upload a watermark under 1MB'] : null"
          @change="handleWatermarkChange"
          class="mb-3"
          outlined
          dense
        ></v-file-input>
      </v-card-text>

      <v-card-text>
        <v-text-field
          v-if="isWhitelisted"
          v-model="form.companyName"
          :hide-details="!$v.form.companyName.$anyError"
          @blur="$v.form.companyName.$touch"
          autocomplete="company-name"
          label="Company Name"
          class="mb-3"
          outlined
          dense
        />

        <v-text-field
          v-model="form.name"
          :hide-details="!$v.form.name.$anyError"
          :error-messages="$v.form.name.$anyError ? ['Name is required'] : null"
          @blur="$v.form.name.$touch"
          autocomplete="user-name"
          label="Name"
          class="mb-3"
          outlined
          dense
        />

        <v-text-field
          v-model="form.phone"
          :hide-details="!$v.form.phone.$anyError"
          :error-messages="$v.form.phone.$anyError ? ['Please enter a correct phone number'] : null"
          @blur="$v.form.phone.$touch"
          autocomplete="user-phone"
          label="Phone"
          class="mb-3"
          outlined
          dense
        />

        <v-text-field
          v-model="form.currentPassword"
          :hide-details="!$v.form.currentPassword.$anyError"
          :error-messages="$v.form.currentPassword.$anyError ? ['Please enter a valid password'] : null"
          @blur="$v.form.currentPassword.$touch"
          autocomplete="user-currentPassword"
          :append-icon="shouldPasswordBeVisible.current ? 'visibility' : 'visibility_off'"
          :type="shouldPasswordBeVisible.current ? 'text' : 'password'"
          @click:append="shouldPasswordBeVisible.current = !shouldPasswordBeVisible.current"
          label="Current Password"
          class="mb-3"
          outlined
          counter
          dense
        />

        <v-text-field
          v-model="form.newPassword"
          :hide-details="!$v.form.newPassword.$anyError"
          :error-messages="$v.form.newPassword.$anyError ? ['Please enter a valid password'] : null"
          @blur="$v.form.newPassword.$touch"
          autocomplete="user-newPassword"
          :append-icon="shouldPasswordBeVisible.new ? 'visibility' : 'visibility_off'"
          :type="shouldPasswordBeVisible.new ? 'text' : 'password'"
          @click:append="shouldPasswordBeVisible.new = !shouldPasswordBeVisible.new"
          label="New Password"
          class="mb-3"
          outlined
          counter
          dense
        />

        <v-text-field
          v-model="form.confirmPassword"
          :hide-details="!$v.form.confirmPassword.$anyError"
          :error-messages="$v.form.confirmPassword.$anyError ? ['Password does not match'] : null"
          @blur="$v.form.confirmPassword.$touch"
          autocomplete="user-confirmPassword"
          :append-icon="shouldPasswordBeVisible.confirm ? 'visibility' : 'visibility_off'"
          :type="shouldPasswordBeVisible.confirm ? 'text' : 'password'"
          @click:append="shouldPasswordBeVisible.confirm = !shouldPasswordBeVisible.confirm"
          label="Confirm Password"
          class="mb-3"
          outlined
          counter
          dense
        />

        <v-combobox
          v-if="canUseAPI"
          v-model="form.IPAddresses"
          label="Whitelisted IP Addresses"
          :allow-overflow="false"
          placeholder="Use enter to save value"
          multiple
          outlined
          dense
          small-chips
          deletable-chips

          :hide-details="!$v.form.IPAddresses.$anyError"
          :error-messages="$v.form.IPAddresses.$anyError ? ['Please enter valid IP values'] : null"
          @input="$v.form.IPAddresses.$touch"
        ></v-combobox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          depressed
          class="mr-3"
          color="primary"
          @click="emitClose"
        >
          Cancel
        </v-btn>

        <v-btn
          text
          depressed
          color="primary"
          @click="handleSubmit"
          :disabled="isLoading"
          :loading="isLoading"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Import helper validation functions
import subnetValidator from "@/helpers/validators/subnetValidator"
import { required, numeric, minLength, sameAs, ipAddress } from "vuelidate/lib/validators"

// Export the SFC
export default {
  // Name of the component
  name: "EditProfile",

  // Accept incoming data from parent
  props: {
    // Whether or not to show the popup
    value: Boolean
  },

  // Local data variables
  data: () => ({
    // to disable the form and buttons
    isLoading: false,
    // always true
    shouldShowDialog: true,
    // whether to make password readable
    shouldPasswordBeVisible: {
      current: false,
      new: false,
      confirm: false
    },

    canUseAPI: false,
    isWhitelisted: false,
    previewLogo: null,
    previewWatermark: null,

    form: {
      logo: null,
      watermark: null,

      name: "",
      phone: "",
      companyName: "",
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      IPAddresses: []
    }
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the profile object from Vuex store
     *
     * @returns {Object}
     */
    authUser() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Get the logo URL from profile in Vuex store
     *
     * @returns {String|Null}
     */
    currentUserLogo() {
      return this.$store.getters["auth/profileLogo"]
    },

    /**
     * Get the watermark URL from profile in Vuex store
     *
     * @returns {String|Null}
     */
    currentUserWatermark() {
      return this.$store.getters["auth/profileWatermark"]
    },
  },

  /**
   * Define validation constraints for the form
   *
   * @returns {Object}
   */
  validations() {
    const validationsObject = {
      form: {
        logo: {
          maxSize: (value) => {
            return value ? value.size <= 1_000_000 : true // max 1mb in size
          },
          mimeType: (value) => {
            return value ? ["image/png", "image/jpeg"].includes(value.type) : true
          }
        },
        watermark: {
          maxSize: (value) => {
            return value ? value.size <= 1_000_000 : true // max 1mb in size
          },
          mimeType: (value) => {
            return value ? ["image/png", "image/jpeg"].includes(value.type) : true
          }
        },
        name: {
          required
        },
        phone: {
          numeric
        },
        companyName: {},
        currentPassword: {
          minLength: minLength(8)
        },
        newPassword: {
          minLength: minLength(8)
        },
        confirmPassword: {
          sameAs: sameAs('newPassword')
        }
      }
    }

    // If the user is allowed to use API
    if (this.canUseAPI) {
      validationsObject.form.IPAddresses = {
        $each: {
          IPorSubnet: (v) => ipAddress(v) || subnetValidator(v)
        }
      }
    }

    // If the user has entered new password, make current password required
    if (this.form.newPassword) {
      validationsObject.form.currentPassword.required = required
    }

    return validationsObject
  },

  // Define local method functions
  methods: {
    /**
     * Emit event to close the dialog
     *
     * @returns {void}
     */
    emitClose() {
      this.$emit("close")
    },

    /**
     * Handle the event when the image changes in input
     *
     * @returns {void}
     */
    handleLogoChange() {
      // if an image is selected
      if (this.form.logo && this.form.logo instanceof File) {
        this.previewLogo = URL.createObjectURL(this.form.logo)
      }
      // if an image has been removed, fallback to default
      else {
        this.previewLogo = this.currentUserLogo
      }
    },

    /**
     * Handle the event when the image changes in input
     *
     * @returns {void}
     */
    handleWatermarkChange() {
      // if an image is selected
      if (this.form.watermark && this.form.watermark instanceof File) {
        this.previewWatermark = URL.createObjectURL(this.form.watermark)
      }
      // if an image has been removed, fallback to default
      else {
        this.previewWatermark = this.currentUserWatermark
      }
    },

    /**
     * Make network request to the server API
     *
     * @returns {void}
     */
    async handleSubmit() {
      await this.$v.$touch()

      if (this.$v.$anyError) {
        return false
      }

      this.isLoading = true

      try {
        const formData = new FormData()
        formData.append("_method", "PUT")

        formData.append("name", this.form.name)
        formData.append("phone", this.form.phone)
        formData.append("company_name", this.form.companyName)
        formData.append("new_password", this.form.newPassword)
        formData.append("current_password", this.form.currentPassword)
        formData.append("confirm_password", this.form.confirmPassword)

        // compute what to do with the logo
        formData.append("logo_action", this.form.logo === null ? "ignore" : "update")

        // if we don't have a logo to begin with, don't send anything
        if (this.form.logo !== null) {
          formData.append("logo_file", this.form.logo)
        }

        // compute what to do with the watermark
        formData.append("watermark_action", this.form.watermark === null ? "ignore" : "update")

        // if we don't have a watermark to begin with, don't send anything
        if (this.form.watermark !== null) {
          formData.append("watermark_file", this.form.watermark)
        }

        // if the user is allowed to use API
        if (this.canUseAPI) {
          formData.append("ip_addresses", this.form.IPAddresses)
        }

        await axios({
          url: "/api/profile",
          method: "POST",
          data: formData
        })

        // hide the dialog
        this.emitClose()

        // show the alert
        this.$store.dispatch("toasts/add", { text: "Account updated." })

        // let the app refresh user data
        window.dispatchEvent(new CustomEvent('fetchAuthUserProfile', {
          detail: {
            module: "Components/Profile/Edit",
            origin: "components/Profile/Edit/handleSubmit"
          }
        }))
      } catch (error) {
        logger({ type: "Update Profile Error", error })

        if (error.response.data.message) {
          this.$store.dispatch("toasts/add", { text: error.response.data.message })
        }
        else {
          this.$store.dispatch("toasts/add", { text: "Could not update account." })
        }
      } finally {
        this.isLoading = false
      }
    }
  },

  /**
   * As soon as the component has been created
   *
   * @returns {void}
   */
  created() {
    if (this.authUser) {
      this.form = {
        logo: null,
        watermark: null,
        name: this.authUser.name || "",
        phone: this.authUser.phone || "",
        companyName: this.authUser.company_name || null,
        newPassword: "",
        currentPassword: "",
        confirmPassword: "",
        IPAddresses: []
      }

      this.previewLogo = this.currentUserLogo
      this.previewWatermark = this.currentUserWatermark
      this.isWhitelisted = this.authUser.is_whitelisted
      this.canUseAPI = this.authUser.can_use_api

      if (this.canUseAPI) {
        this.form.IPAddresses = this.authUser.ip_addresses || []
      }
    }
  }
}
</script>
